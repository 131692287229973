// src/components/Signup.js

import React, { Component } from 'react';
import createAPI from '../service/ApiService';

class SignUp extends Component {

    //생성자
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // 여기에서 회원가입 로직을 구현합니다.
    
    // 오브젝트에서 form에 저장된 데이터를 맵의 형태로 바꿔줌.
    const data = new FormData(event.target);

    const userDTO = {
      name: data.get("name"),
      nickname: data.get("nickname"),
       password: data.get("password")
    }

    console.log('Name:', userDTO.name);
    console.log('Nickname:', userDTO.nickname);
    console.log('Password:', userDTO.password);

    try {
      // createAPI를 사용하여 로그인 API 호출
      await createAPI().post('/member/signup', userDTO)
      .then(response => {
        // 정상 응답 처리
        window.location.href = "/login"; // redirect
      })
      .catch(error => {
        // 오류 처리
        if (error.response && error.response.status === 409) {
          console.error('중복된 닉네임 입니다.');
          alert("중복 닉네임 입니다.");
        }else{
          alert(error.message);
        }
      });
    } catch (error) {
      // 오류 처리
      console.error('오류:', error);
    }
  };

  render() {
    return (
      <div>
        <h2>회원가입</h2>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="name">이름:</label>
            <input
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div>
            <label htmlFor="nickname">닉네임:</label>
            <input
              type="text"
              id="nickname"
              name="nickname"
              required
            />
          </div>
          <div>
            <label htmlFor="password">비밀번호:</label>
            <input
              type="password"
              id="password"
              name="password"
              required
            />
          </div>
          <button type="submit">가입하기</button>
        </form>
      </div>
    );
  }
}

export default SignUp;
