import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";

//client 객체 생성 및 서버주소 입력
//const sock = new SockJs(url); //endpoint
//stomp로 감싸기

let stompClient = null;
let Sock = null;

const url = "https://facechat-project.site/ws";

export const connectWebSocket = (token, roomId, onConnected, connectError, onMessageReceived, onPrivateMessage) => {
    try {
        Sock = new SockJS(url+"?token="+token);
        stompClient = Stomp.over(Sock);

        stompClient.connect({}, onConnected, connectError);

        stompClient.onWebSocketClose = (e) => {
            if(e.code === 2000){
                alert('토큰 시간 만료: 재입장 해주세요.');
                window.location.href = '/';
            } else {
                alert('서버 오류');
                window.location.href = '/';
            }
        };
    } catch (error) {
        console.log("연결 시도 중 예외 발생: ", error);
    }
    return stompClient;
};

export const disconnectWebSocket = () => {
    if (stompClient) {
        stompClient.deactivate();
    }
    if (Sock) {
        Sock.close();
    }
};

export const sendMessage = (receiverName, status, data, myName, roomId) => {
    let chatMessage = {
        senderName: myName,
        receiverName: receiverName,
        status: status,
        roomId: roomId,
        data: data,
    };

    if (receiverName === "") {
        stompClient.send("/chatroom/" + roomId, {}, JSON.stringify(chatMessage));
    } else {
        stompClient.send("/app/private-message", {}, JSON.stringify(chatMessage));
    }
};
