import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ChatRoom from './components/ChatRoom'
import Header from './layouts/Header';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Login from './components/Login';
import CreateRoom from './components/CreateRoom';

const App = () => {

  return (
    <div className='App'>
		<BrowserRouter>
				<Header />
				<Routes>
					<Route path="/" exact element={<Home />}></Route>
					<Route path="/chatroom/:roomId" element={<ChatRoom />}></Route>
					<Route path="/signup" element={<SignUp />}></Route>
					<Route path="/login" element={<Login />}></Route>
					<Route path="/createroom" element={<CreateRoom />}></Route>
				</Routes>
			</BrowserRouter>
  </div>
  );
}

export default App;
