import React, { useState } from 'react';
import createAPI from '../service/ApiService';

const ACCESS_TOKEN = "ACCESS_TOKEN";

function Login() {
  const [formData, setFormData] = useState({
    nickname: '',
    password: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { nickname, password } = formData;

    try {

      // createAPI를 사용하여 로그인 API 호출
      await createAPI().post('/member/login', { nickname, password })
      .then(response => {
        // 정상 응답 처리
        if (response.data && response.data.accessToken) {
          // 로컬 스토리지에 토큰 저장
          localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
          // token이 존재하는 경우 home으로 이동
          window.location.href = "/";
        }
      })
      .catch(error => {
        // 오류 처리
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized access. Please login.');
        }else if(error.response && error.response.status === 400){
          alert('닉네임과 비밀번호를 확인해주세요.')
        }else {
          console.error('An error occurred:', error.message);
        }
      });
    } catch (error) {
      // 오류 처리
      console.error('로그인 오류:', error);
    }

  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <h2>로그인</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="nickname">닉네임:</label>
          <input
            type="text"
            id="nickname"
            name="nickname"
            value={formData.nickname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">비밀번호:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit">로그인</button>
      </form>
    </div>
  );
}

export default Login;
