import axios from "axios";

const ACCESS_TOKEN = "ACCESS_TOKEN";

// Axios 인스턴스를 생성하는 함수
function createAPI() {
  // 로컬 스토리지에서 ACCESS TOKEN 가져오기
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  
  // 기본 헤더 설정
  let headers = {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  };

  // 액세스 토큰이 존재하는 경우, Authorization 헤더 추가
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  // Axios 인스턴스 생성
  const API = axios.create({
    baseURL: 'https://facechat-project.site/api',
    timeout: 1000,
    headers,
    withCredentials: true,
  });

  return API;
}

// 사용 예시:
// const api = createAPI();
// api.get('/path').then(response => {
//   console.log(response.data);
// });
//ec2에 업로드시 URL 변경 필수

export default createAPI;
