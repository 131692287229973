import '../css/Chats.css'

const Chat =({index, chat, myName})=>{
    const isMyChat = chat.name === myName;

    return (
        <div className={`chat ${isMyChat ? 'my-chat' : ''}`}>
          {chat.status === 'MESSAGE' ? (
            // 'MESSAGE' 상태인 경우 chat.name과 chat.data를 표시합니다.
            <div>
                <div>{chat.name}</div>
                <div>{chat.data}</div>
            </div>
          ) : (
            // 'JOIN' 또는 'LEAVE' 상태인 경우 입장 또는 퇴장 메시지를 가운데 정렬합니다.
            <div className="info-message">
             {chat.name}님이 {chat.status === 'JOIN' ? '입장했' : '나갔'}습니다.
            </div>
          )}
        </div>
      );
}

export default Chat;