import React, { useEffect, useRef} from 'react';
import '../../App.css';

const Video =({name, stream})=>{
    const videoRef=useRef();

    useEffect(()=>{
        if(videoRef.current) videoRef.current.srcObject=stream;
    },[stream]);
    return(
        <div className='video-box'>
            <video autoPlay playsInline 
            className='video-component'ref={videoRef}>
            </video>
            <div style={{height: "5%"}}>{name}</div>
        </div>
    );
}

export default Video;