import React, { useEffect, useState } from 'react';
import createAPI from '../service/ApiService';
import {Pagination} from 'react-bootstrap';
import Room from './Room';

const Home = () => {

	const [rooms, setRooms] = useState([]);
	const [last, setLast] = useState('');
	const [page, setPage] = useState(0);

	useEffect(() => {
		const getRoomList = async () =>{
			try{
				await createAPI().get("?page="+page)
				.then(response => {
					setRooms(response.data.content);
					setLast(response.data.last);
				});
			} catch (error){
				
			}
		};
		console.log("페이지: "+page)

		getRoomList();
	  }, [page]);

	const prev = () =>{
		setPage(page-1);
	}

	const next = () =>{
		setPage(page+1);
	}


	return (
		<div>

			<h3>안녕하세요. 메인페이지 입니다.</h3>
			<div>리스트 보기</div>
			{rooms.map(room => <Room key={room.id} room={room} />)}
			<br />
			<div className="d-flex justify-content-center">
				<Pagination>
					{page === 0 ? 
						<Pagination.Item onClick={prev} disabled>Prev</Pagination.Item> : 
						<Pagination.Item onClick={prev}>Prev</Pagination.Item>}
					{last === true ? 
						<Pagination.Item onClick={next} disabled>Next</Pagination.Item> : 
						<Pagination.Item onClick={next}>Next</Pagination.Item>}
				</Pagination>
			</div>
		</div>
	);
};

export default Home;