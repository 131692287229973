import React, { useEffect } from 'react';
import createAPI from '../service/ApiService';
import { useNavigate } from 'react-router-dom';

function CreateRoom() {
    
  const navigate = useNavigate();

  useEffect(() => {

    const accessToken = localStorage.getItem("ACCESS_TOKEN");
		if (accessToken && accessToken !== null) {
			console.log("있음!")
		}else{
      navigate('/login');
      alert("로그인 후 이용해주세요.")
		}
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const data = new FormData(event.target);
    const roomName = data.get("roomName");
    const roomDetail = data.get("roomDetail");

    console.log('roomName:', roomName);
    console.log('roomDetail:', roomDetail);

    try {
      // createAPI를 사용하여 로그인 API 호출
      await createAPI().post('/room/create', { roomName, roomDetail })
      .then(response => {
        // 정상 응답 처리
        if (response.id !== null) {
          window.location.href = "/";
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("ACCESS_TOKEN");
          alert("재로그인 해주세요.")
          window.location.href = "/login";
        }else{
          alert(error.message);
        }
      });
    } catch (error) {
      // 오류 처리
      console.error('오류:', error);
    }
  };

  return (
    <div>
      <h2>방생성</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="roomName">방이름:</label>
          <input
            type="text"
            id="roomName"
            name="roomName"
            required
          />
        </div>
        <div>
          <label htmlFor="roomDetail">방 설명:</label>
          <input
            type="text"
            id="roomDetail"
            name="roomDetail"
            required
          />
        </div>
        <button type="submit">생성</button>
      </form>
    </div>
  );
}

export default CreateRoom;
