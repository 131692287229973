import React from 'react';
import { Card,Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import createAPI from '../service/ApiService';

const Room = ({room}) => {

    const navigate = useNavigate();

    const handleOpenRoom = async ()=>{
        // 로컬 스토리지에서 ACCESS TOKEN 가져오기
	    const accessToken = localStorage.getItem("ACCESS_TOKEN");

        try{//로그인 안 되어있음 입장불가!
			if (accessToken !== null) {
				//home에서 먼저 토큰 확인
				//정상이면 연결
				await createAPI().get('/member/check')
				.then(response => {
					navigate('/chatroom/'+room.id, {state: {token: response.data.accessToken}});
				})
				.catch(error => {
					alert("로그인 만료");
					localStorage.removeItem("ACCESS_TOKEN");
					window.location.href = "/login";
				  });
			}else{
				alert("로그인 후 이용해주세요.");
			}
		}catch(error){}
	}

	const handleDeleteRoom = async ()=>{
        // 로컬 스토리지에서 ACCESS TOKEN 가져오기
	    const accessToken = localStorage.getItem("ACCESS_TOKEN");

        try{//로그인 안 되어있음 방 삭제 불가.
			if (accessToken !== null) {
				await createAPI().delete('/room/'+room.id)
				.then(() => {
					window.location.href = "/";
				})
				.catch(error => {
					if(error.response && error.response.status === 401){
						alert("로그인 만료");
						localStorage.removeItem("ACCESS_TOKEN");
						window.location.href = "/login";
					}else{
						alert("삭제 권한이 없습니다.");
					}
				  });
			}else{
				alert("로그인 후 이용해주세요.");
			}
		}catch(error){}
	}


	return (
		<Card>
			<Card.Body>
				<Card.Title>{room.roomName}</Card.Title>
                <Card.Text>{room.roomDetail}</Card.Text>
                <Button variant="primary" onClick={handleOpenRoom}>입장하기</Button>
				<Button variant="dark" onClick={handleDeleteRoom}>삭제하기</Button>
			</Card.Body>
		</Card>
	);
};

export default Room;